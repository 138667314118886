import React, { useState, useEffect, useMemo } from "react";
import { Col } from "reactstrap";
import {
    HeavyResourceIcon,
    LightResourceIcon,
    MediumResourceIcon,
} from "../../../Assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { getHeaviestResources } from "../../../redux/dashboard/dashboardSlice";
import { extractFileName, truncateUrl } from "../Helper";
import SolwestResources from "./SolwestResources";

const HeavyResources = ({ userId }) => {
    const dispatch = useDispatch();
    const { heavyResources } = useSelector((state) => state.dashboard);
    const [selectedView, setSelectedView] = useState("heavy");

    useEffect(() => {
        dispatch(getHeaviestResources(userId));
    }, [dispatch, userId]);

    const list = heavyResources?.data;

    const sortedList = useMemo(() => {
        if (!Array.isArray(list)) return [];
        return [...list].sort((a, b) => {
            const bSize = parseInt(b.transfersize) || 0;
            const aSize = parseInt(a.transfersize) || 0;
            return bSize - aSize;
        });
    }, [list]);

    const formatBytes = (bytes) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    };

    const renderResourceItem = (item, index) => (
        <div key={index} className="progress-item">
            <div className="progress-label">
                <div>
                    {index <= 2 ? (
                        <HeavyResourceIcon />
                    ) : index <= 4 ? (
                        <MediumResourceIcon />
                    ) : index <= 6 ? (
                        <LightResourceIcon />
                    ) : null}
                    <span className="left">
                        <a href={item.name} className="link" title={item.name}>
                            {extractFileName(item.name)}{" "}
                            {truncateUrl(item.name)}
                        </a>
                    </span>
                </div>
                <div className="right">
                    {formatBytes(parseInt(item.transfersize) || 0)}
                </div>
            </div>
        </div>
    );

    return (
        <Col md="6">
            <div className="card">
                <div className="card-header">
                    <div className="d-flex gap-3">
                        <h4
                            className={`dash-card-title mb-0 h6 tab-color ${
                                selectedView === "heavy" ? "tab-active" : ""
                            }`}
                            onClick={() => setSelectedView("heavy")}
                            style={{ cursor: "pointer" }}
                        >
                            Heavy Resources
                        </h4>
                        <h4
                            className={`dash-card-title mb-0 h6 tab-color ${
                                selectedView === "slow" ? "tab-active" : ""
                            }`}
                            onClick={() => setSelectedView("slow")}
                            style={{ cursor: "pointer" }}
                        >
                            Slowest resources
                        </h4>
                    </div>
                </div>
                {selectedView === "heavy" && (
                    <div className="card-body scroll--height pt-0 top-click-section">
                        {sortedList.length > 0 ? (
                            sortedList.map(renderResourceItem)
                        ) : (
                            <div>No heavy resources data available.</div>
                        )}
                    </div>
                )}
                {selectedView === "slow" && (
                    <SolwestResources userId={userId} />
                )}
            </div>
        </Col>
    );
};

export default HeavyResources;
