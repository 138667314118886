import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
} from "reactstrap";
import { getCountryWiseData } from "../../../redux/dashboard/dashboardSlice";
import getCountryFlag from "../Helper";

function calculateTotalAndAvg(objArray) {
    const total = objArray.reduce((sum, obj) => sum + parseInt(obj.count), 0);
    const avg = total / objArray.length;
    return {
        total: total,
        avg: Math.round(avg),
    };
}

const CountryWiseData = (props) => {
    const dispatch = useDispatch();
    const cache = {};

    const { countryWiseData, status, error } = useSelector(
        (state) => state.dashboard
    );

    useEffect(() => {
        if (props.userId) {
            dispatch(getCountryWiseData(props.userId));
        }
    }, [dispatch]);

    const [countries, setCountries] = useState();
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        if (!countryWiseData) {
            return;
        }
        const fetchCountryData = async () => {
            let total = 0;
            const updatedCountries = await Promise.all(
                countryWiseData.map(async (country) => {
                    try {
                        total += parseInt(country.count, 10);
                        return {
                            country: country.country,
                            fullname: country.country,
                            flag_url: country.country_flag,
                            count: country.count,
                        };
                    } catch (error) {
                        console.error(
                            `Error fetching data for country ${country.country}: ${error}`
                        );
                        return null;
                    }
                })
            );
            const countriesWithAvg = updatedCountries
                .map((country) => {
                    if (country !== null) {
                        country.avg = Math.round((country.count / total) * 100);
                    }
                    return country;
                })
                .sort((a, b) => b.count - a.count); // sort the countries in descending order
            setCountries(
                countriesWithAvg.filter((country) => country !== null)
            );
            setTotalCount(total);
        };
        fetchCountryData();
    }, [countryWiseData]);

    // const dispatch = useDispatch();
    // const cache = {};

    // const { countryWiseData, status, error } = useSelector(
    //     (state) => state.dashboard
    // );

    // useEffect(() => {
    //     if (props.userId) {
    //         dispatch(getCountryWiseData(props.userId));
    //     }
    // }, [dispatch]);

    // const output = {
    //     data: [],
    // };

    // let batch = [];
    // const BATCH_SIZE = 3;

    // countryWiseData?.forEach((item) => {
    //     const code = item.country;

    //     if (cache[code]) {
    //         handleCountry(cache[code], item);
    //         return;
    //     }

    //     batch.push(code);
    //     if (batch.length === BATCH_SIZE) {
    //         fetchCountryBatch(batch);
    //         batch = [];
    //     }
    // });

    // if (batch.length > 0) {
    //     fetchCountryBatch(batch);
    // }

    // function fetchCountryBatch(codes) {
    //     codes.forEach((code) => {
    //         const url = `https://restcountries.com/v3.1/alpha/${code}`;

    //         fetch(url)
    //             .then((resp) => resp.json())
    //             .then((data) => {
    //                 cache[code] = data[0];
    //                 const country = cache[code];
    //                 handleCountry(
    //                     country,
    //                     countryWiseData.find((c) => c.country === code)
    //                 );
    //             });
    //     });
    // }

    // function handleCountry(country, inputCountry) {
    //     output.data.push({
    //         country_initial: inputCountry.country,
    //         country_name: country?.name?.common,
    //         flag: country?.flags?.png,
    //         count: inputCountry.count,
    //     });
    //     return output;
    // }
    // console.log(output);

    // if (!countries.length) {
    //     return <div>Loading...</div>;
    // }

    return (
        <Col md="4" className="country-section">
            <h3 className="h2 mb-4 country-list-height">{totalCount}</h3>
            <div className="progress-item-wrapper">
                {countries
                    ? countries.map((countryData, index) => {
                          return (
                              <div
                                  key={index}
                                  className="progress-item with--icon"
                              >
                                  <img
                                      className="country_flag_image flage-image"
                                      src={getCountryFlag(countryData.country)}
                                  />
                                  <div className="content">
                                      <div className="progress-label">
                                          <span className="left">
                                              {countryData.country}
                                          </span>
                                          <div className="right">
                                              {countryData.avg}%
                                          </div>
                                      </div>
                                      <div className="progress">
                                          <div
                                              className="progress-bar"
                                              role="progressbar"
                                              style={{
                                                  width: `${countryData.avg}%`,
                                              }}
                                              aria-valuenow={countryData.avg}
                                              aria-valuemin="0"
                                              aria-valuemax="100"
                                          ></div>
                                      </div>
                                  </div>
                              </div>
                          );
                      })
                    : ""}

                {/* <div className="progress-item with--icon">
                    <svg
                        className="icon"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_1004_6437)">
                            <path
                                d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                                fill="#F0F0F0"
                            />
                            <path
                                d="M14.0005 -0.000976562C8.45002 -0.000976562 3.65415 3.22914 1.38965 7.91208H26.6113C24.3468 3.22914 19.5509 -0.000976562 14.0005 -0.000976562Z"
                                fill="#FF9811"
                            />
                            <path
                                d="M14.0005 28C19.5509 28 24.3468 24.7699 26.6113 20.0869H1.38965C3.65415 24.7699 8.45002 28 14.0005 28Z"
                                fill="#6DA544"
                            />
                            <path
                                d="M14.0004 18.869C16.6898 18.869 18.8699 16.6888 18.8699 13.9994C18.8699 11.3101 16.6898 9.12988 14.0004 9.12988C11.311 9.12988 9.13086 11.3101 9.13086 13.9994C9.13086 16.6888 11.311 18.869 14.0004 18.869Z"
                                fill="#0052B4"
                            />
                            <path
                                d="M14.0005 17.043C15.6814 17.043 17.044 15.6804 17.044 13.9995C17.044 12.3187 15.6814 10.9561 14.0005 10.9561C12.3196 10.9561 10.957 12.3187 10.957 13.9995C10.957 15.6804 12.3196 17.043 14.0005 17.043Z"
                                fill="#F0F0F0"
                            />
                            <path
                                d="M14.0005 10.2441L14.9394 12.3736L17.2529 12.1219L15.8783 13.9997L17.2529 15.8776L14.9394 15.6259L14.0005 17.7554L13.0615 15.6259L10.748 15.8775L12.1227 13.9997L10.748 12.1219L13.0615 12.3736L14.0005 10.2441Z"
                                fill="#0052B4"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1004_6437">
                                <rect width="28" height="28" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div className="content">
                        <div className="progress-label">
                            <span className="left">India</span>
                            <div className="right">67%</div>
                        </div>
                        <div className="progress">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    width: "67%",
                                }}
                                aria-valuenow="67"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                    </div>
                </div>
                <div className="progress-item with--icon">
                    <svg
                        className="icon"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_1004_6446)">
                            <path
                                d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                                fill="#FFDA44"
                            />
                            <path
                                d="M0 14C0 6.26806 6.26806 0 14 0C21.7319 0 28 6.26806 28 14"
                                fill="#338AF3"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1004_6446">
                                <rect width="28" height="28" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div className="content">
                        <div className="progress-label">
                            <span className="left">Ukraine</span>
                            <div className="right">62%</div>
                        </div>
                        <div className="progress">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    width: "62%",
                                }}
                                aria-valuenow="62"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                    </div>
                </div>
                <div className="progress-item with--icon">
                    <svg
                        className="icon"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_1004_6455)">
                            <path
                                d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                                fill="#D80027"
                            />
                            <path
                                d="M13.9998 7.30469L15.5107 11.9547H20.4L16.4444 14.8285L17.9553 19.4786L13.9998 16.6047L10.0442 19.4786L11.5552 14.8285L7.59961 11.9547H12.4889L13.9998 7.30469Z"
                                fill="#FFDA44"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1004_6455">
                                <rect width="28" height="28" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div className="content">
                        <div className="progress-label">
                            <span className="left">Japan</span>
                            <div className="right">40%</div>
                        </div>
                        <div className="progress">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    width: "40%",
                                }}
                                aria-valuenow="40"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                    </div>
                </div>
                <div className="progress-item with--icon">
                    <svg
                        className="icon"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipPath="url(#clip0_1004_6464)">
                            <path
                                d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                                fill="#F0F0F0"
                            />
                            <path
                                d="M4.09952 23.8996C-1.36781 18.4323 -1.36781 9.56797 4.09952 4.10059C4.09914 4.10119 12.173 14.0001 12.173 14.0001L4.09952 23.8996Z"
                                fill="black"
                            />
                            <path
                                d="M12.1741 14.0003L1.85702 7.02832C1.53715 7.58433 1.25507 8.16467 1.0127 8.76536L6.23584 14.0003L1.01286 19.2356C1.25502 19.8358 1.53682 20.4158 1.85647 20.9714L12.1741 14.0003Z"
                                fill="#FFDA44"
                            />
                            <path
                                d="M27.8817 12.1738H12.1737L4.10055 4.10059C3.23058 4.97055 2.47474 5.95444 1.85699 7.02801L8.81537 13.9999L1.85645 20.9709C2.4742 22.0448 3.23036 23.029 4.10055 23.8992L12.1737 15.826H27.8817C27.9595 15.2282 28.0002 14.6188 28.0002 13.9999C28.0002 13.381 27.9595 12.7716 27.8817 12.1738Z"
                                fill="#6DA544"
                            />
                            <path
                                d="M5.47559 25.104C7.83639 26.9186 10.7915 27.9982 13.9993 27.9982C20.4677 27.9982 25.9105 23.6111 27.517 17.6504H12.9292L5.47559 25.104Z"
                                fill="#0052B4"
                            />
                            <path
                                d="M27.517 10.3468C25.9105 4.38611 20.4677 -0.000976562 13.9993 -0.000976562C10.7915 -0.000976562 7.83639 1.07861 5.47559 2.89325L12.9292 10.3468H27.517Z"
                                fill="#D80027"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1004_6464">
                                <rect width="28" height="28" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div className="content">
                        <div className="progress-label">
                            <span className="left">Africa</span>
                            <div className="right">32%</div>
                        </div>
                        <div className="progress">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    width: "32%",
                                }}
                                aria-valuenow="32"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                    </div>
                </div> */}
            </div>
        </Col>
    );
};

export default CountryWiseData;
