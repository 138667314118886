import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    SlowResourceIcon,
    FastResourceIcon,
    FastestResourceIcon,
} from "../../../Assets/icons";
import { getLongestDurationResources } from "../../../redux/dashboard/dashboardSlice";
import { extractFileName, truncateUrl } from "../Helper";

const SlowestResources = ({ userId }) => {
    const dispatch = useDispatch();
    const { longestDurationResources } = useSelector(
        (state) => state.dashboard
    );

    useEffect(() => {
        dispatch(getLongestDurationResources(userId));
    }, [dispatch, userId]);

    const list = longestDurationResources?.data;

    const sortedList = useMemo(() => {
        if (!Array.isArray(list)) return [];
        return [...list].sort((a, b) => {
            const bDuration = parseInt(b.duration) || 0;
            const aDuration = parseInt(a.duration) || 0;
            return bDuration - aDuration;
        });
    }, [list]);

    const formatDuration = (duration) => {
        const ms = parseInt(duration) || 0;
        if (ms < 1000) return `${ms} ms`;
        return `${(ms / 1000).toFixed(2)} s`;
    };

    const renderResourceItem = (item, index) => (
        <div key={index} className="progress-item">
            <div className="progress-label">
                <div>
                    {index <= 2 ? (
                        <SlowResourceIcon />
                    ) : index <= 4 ? (
                        <FastResourceIcon />
                    ) : index <= 6 ? (
                        <FastestResourceIcon />
                    ) : null}
                    <span className="left">
                        <a href={item.name} className="link" title={item.name}>
                            {extractFileName(item.name)}{" "}
                            {truncateUrl(item.name)}
                        </a>
                    </span>
                </div>
                <div className="right">{formatDuration(item.duration)}</div>
            </div>
        </div>
    );

    return (
        <div className="card-body scroll--height pt-0 top-click-section">
            {sortedList.length > 0 ? (
                sortedList.map(renderResourceItem)
            ) : (
                <div>No slow resources data available.</div>
            )}
        </div>
    );
};

export default SlowestResources;
